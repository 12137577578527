.ant-modal-footer {
    box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.1);
}

.ant-modal-footer {
    display: flex;
    place-content: space-evenly;
}

.ant-upload-drag {
    width: auto !important;
    margin: 24px;
    background-color: #fff !important;
}

.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #6c7c8c !important;
}

.uploaded-images-list {
    margin: 12px;
    max-height: 200px;
    overflow-y: scroll;
}

.upload-payment-modal .ant-list-item {
    padding: 12px !important;
    border-radius: 8px;
    margin: 12px;
    background-color: #f2f2f2;
}

.confirmation-modal .ant-list-item {
    padding: 12px !important;
    border-radius: 8px;
    margin: 12px 0px;
    background-color: #f2f2f2;
}