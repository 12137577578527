.sum-wrapper{
    border: solid 1px #dedede;
    border-radius: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:24px
}
.sum-text{
    font-weight: 500;
    font-size: 20px;
    padding: 24px 24px 0 24px
}
.bill-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 12px;
  font-weight: 500;
  padding: 0 24px 24px 24px;
  border-bottom: 1px solid #dedede;

}
.amount-calc{
  display: flex;
  justify-content: space-between;  
}
.total-amount{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: space-between; */
    font-weight: 500;
    padding: 0 24px 24px 24px;
    border-bottom: 1px solid #dedede;
}
.pay-text{
    font-weight: 500;
    font-size: 18px;
    }
    .payment-option{
        display: flex;
        flex-direction: column;
    padding: 0 24px 24px 24px;
    gap:1rem;
    border-bottom: 1px solid #dedede;
    
    }
    .card{
        display: flex;
        align-items: center;
        gap:1rem;
    }
    .reward{
        padding: 0 24px 24px 24px;
        display: flex;
        align-items: center;
        gap:4px
    }
    .payment-type{
        display: flex;
        justify-content: space-between;
        
    }
    .pay-btn{
        display: flex;
        gap: 19px;
    }
    .pay-tI{
        font-weight: 500;
        opacity: 0.6;
    }
    .pay-tII{
        color: #2973f0;
        font-weight: 500;
        cursor: pointer;

    }
    .payment-link{
        display: flex;
        padding: 16px;
        border-radius: 8px;
        background-color: #f2f2f2;
        justify-content: space-between;
        font-weight: 500;
    }
    .payment-links{
       border-bottom: 1px solid #0a243f;
        color:  #0a243f;
        font-size: 14px;
    }
    .link-wrapper{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .link-wrapperI{
        display: flex;
        gap: 11px;
        align-items: center;
        cursor: pointer;
    }