.ant-table-thead .ant-table-cell {
  background: #e8e8e8 !important;
}

.tag-font-style {
  background-color: #e8e8e8;
  border: none;
  font-weight: 500;
}
.requested {
  color: #0a243f;
  background-color: #e8e8e8;
}
.colorSuccess {
  color: #006644;
  background-color: #abf5d1;
}
.colorDanger {
  color: #f0483e;
  background-color: rgb(255, 224, 230); /* Light Pinkish Background */
}

.disabled {
  color: #6c7b8b;
  background-color: #e8e8e8;
}
.locally {
  padding: 3px 10px;
  color: #0a243f;
  font-weight: 500;
  background-color: #dedede;
}
.to_be_settled {
  padding: 3px 10px;
  color: #0a243f;
  font-weight: 500;
  background-color: #f8e6b6;
}
.SETTLED {
  padding: 3px 10px;
  color: #006644;
  font-weight: 500;
  background-color: #abf5d1;
}
.MOB_library {
  padding: 3px 10px;
  color: #fff;
  font-weight: 500;
  background-color: #467fb6;
}
.quotesent {
  color: #b46433;
  background-color: #fde9c8;
}

.ant-table-tbody .ant-table-row {
  border: solid 1px #dedede;
}

.ant-input-affix-wrapper {
  /* padding: 10px 11px; */
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 8px;
}

.custom-select .ant-select-selection-placeholder {
  color: #0a243f;
  font-family: Inter;
}

.ant-select-selector {
  height: 43px !important;
  padding: 6px 11px !important;
}
.custom-select .ant-select {
  margin-right: 10px;
}

.custom-select .ant-select-arrow {
  color: #0a243f;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}

th {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
}

.ant-tag {
  border-radius: 6px;
}

.rfq-id-class {
  color: #007bff;
  cursor: pointer;
}
.rfq-id-class:hover .ant-col {
  font-size: 14.2px;
}

.rfq-list-table {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

/* .rfq-list-table table tbody tr td:first-child {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
} */
.payment-link-container {
  display: flex;
  flex-direction: column;
}
.link-wrapper-container {
  justify-content: space-between;
}
.link-wrapper-sub-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}
