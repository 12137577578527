.create-address-container {
  display: flex;
  padding: 24px;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  background-color: #ffc5b7;
}
.create-address-sub-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.create-address-sub-container-text-1 {
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
}
.create-address-sub-container-text-2 {
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}
.create-address-button {
  background-color: #0a243f;
  padding: 0 30px;
  border-radius: 8px;
  height: 44px;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
}
