.auto-complete:hover,
.auto-complete:active,
.auto-complete:focus,
.auto-complete:visited,
.auto-complete:focus-within {
  border: 1.6px solid #0a243f !important;
  box-shadow: none;
}

/* .auto-complete .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.auto-complete .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.auto-complete .ant-select-dropdown-menu-item {
  padding-left: 16px;
} */

.auto-complete .ant-select-dropdown-menu {
  max-height: 300px;
}
.auto-complete > .ant-select-selector {
  /* height: 44px !important; */
  padding: 0 !important;
}
.auto-complete-input {
  height: 42px;
  border: none;
}
