.seller-details-main {
  display: flex;
}
.seller-details-main-left {
  width: 60%;
}
.seller-details-main-right {
  width: 40%;
  padding: 50px;
  display: flex;
  justify-content: center;
}
.seller-display-image-text {
  font-size: 20px;
  font-weight: 500;
  color: #0a243f;
  text-align: center;
}
