.search-input .ant-input{
    color: #0a243f;
    font-size: 16px !important; 
    font-weight: 500;
}
/* :where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper-disabled:hover, :where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper[disabled]:hover{
    opacity: 0.65;
} */
.font-italic
{
    font-style: italic;
}
.delivered-text-font
{
    color: #0da72f;
}

.text-subtitle-normal
{
    color: #0a243f;
    font-size: 14px;
    margin-top: 16px;
}
.textTitleParent-head
{
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
}