.addFile-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.1);
}
.submit-btn {
  height: 46px;
  width: 40%;
  font-weight: 500;
  background-color: #0354a3;
}
.cancel-btn {
  height: 46px;
  width: 30%;
  font-weight: 500;
}
.addFile-body{
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap:24px;
    min-height: 60vh
}
.addFile-notify{
    padding: 16px;
    border-radius: 16px;
    background-color: #f8e6b6;
    color: #0a243f;
}
.addFile-input{
    padding: 16px;
    font-size: 16px;
    font-weight: 500;

}
.addFile-modal{
    min-height: 90vh;
    top:5px
    
}
.file-dragger-container {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1890ff;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #40a9ff;
  }

  .ant-upload-drag-icon {
    font-size: 32px;
    color: #1890ff;
  }
}