.headingItem {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3rem;
}

.input-width {
  width: 60%;
  height: 3rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
}
