.custom-step-parent
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon-dot {
  height: 16px;
  width: 16px;
  background-color: #4fb589 !important;
}
.custom-step-parent .ant-steps-item-container .ant-steps-item-tail {
  top: 6px !important;
  width: 100%;
  height: 4px !important;
  left: 10px !important;
  background-color: #4fb589 !important;
}

.custom-step-parent .ant-steps-item-container .ant-steps-item-tail::after {
  background-color: transparent !important;
}
.custom-step-parent .ant-steps-item-container .ant-steps-item-content {
  margin-top: 20px;
}
.custom-step-parent-grey .ant-steps-item-container .ant-steps-item-content {
  margin-top: 20px;
}

.custom-step-parent-grey
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon-dot {
  height: 17px;
  width: 17px;
  background-color: #dedede !important;
}
.custom-step-parent-grey .ant-steps-item-container .ant-steps-item-tail {
  top: 6px !important;
  width: 100%;
  height: 4px !important;
  left: 5px !important;
  background-color: #dedede !important;
}

.custom-step-parent-grey .ant-steps-item-container .ant-steps-item-tail::after {
  background-color: transparent !important;
}
.empty-dot {
  width: 12px; /* Set the width of the empty dot */
  height: 12px; /* Set the height of the empty dot */
  background-color: #fff; /* Set the background color of the empty dot */
  border: solid 3px #dedede; /* Add border for the empty dot if needed */
  border-radius: 50%; /* Make it circular if needed */
  margin-top: 2px;
}
.sub-order-icon-wrapper {
  background-color: #fff !important;
  width: 35px !important;
  max-width: 35px !important;
  height: 35px !important;
  border-radius: 100%;
  border: 2px solid #4fb589;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -7px;
  padding: 4px;
}
.sub-order-icon-wrapper svg {
  background: none !important;
}
