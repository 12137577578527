.reason-input {
  height: 44px;
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  border: 1px solid #bac7d5;
}
.reason-input:hover,
.reason-input:active,
.reason-input:focus,
.reason-input:visited {
  border: 1px solid #0a243f;
}
.comments-textarea {
  border: 1px solid #bac7d5;
  border-radius: 8px;
  resize: none !important;
}
.comments-textarea:hover,
.comments-textarea:active,
.comments-textarea:focus,
.comments-textarea:visited {
  border: 1px solid #0a243f;
}
