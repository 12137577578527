.settleHeaderWrapper {
  padding: 2rem;
  background-color: #42919b;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.settleBtn {
  height: 46px;
  color: #ffffff !important;
  border: solid 1.5px #fff;
  background-color: #42919b;
  font-weight: 500;
  max-width: 111px;
}
.settleStatement {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.userWrapper {
  padding: 24px;

  border-radius: 8px;
  background-color: rgba(10, 36, 63, 0.2);
  display: flex;
  gap: 48px;
}
.userContentAlign {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.SettleContentTextI {
  font-size: 14px;
  color: #fff;
}
.SettleContentText {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.settleCardWrapper {
  display: flex;
  gap: 24px;
}
.settleCardWrapperI {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 24px;
  border-radius: 8px;
  width: 33%;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.anticon-down {
  color: black;
}

.settlementWrapper {
  padding: 2rem;
}

.ant-tabs-tab {
  font-weight: 500;
  color: #989898 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0a243f !important;
}

.ant-tabs-ink-bar {
  background-color: #0a243f !important;
}

.extra-payment {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 16px;
  border-radius: 8px;
  color: #fff;
}
.table_text {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
}
