.customerDetail-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.customerDetail-backGround {
  position: absolute;
  height: 200px;
  background-color: #e8e8e8;
  width: 100%;
}
.main-background {
  position: relative;
}
.customerDetails-styledText{
    font-size: 16px;
  font-weight: 500;
  color: #2973f0;
  cursor: pointer;
}