.main-auto-complete:hover,
.main-auto-complete:active,
.main-auto-complete:focus,
.main-auto-complete:visited,
.main-auto-complete:focus-within {
  /* border: 1.6px solid #0a243f !important; */
  box-shadow: none;
  /* background-color: #fff; */
}

/* .main-auto-complete .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.main-auto-complete .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.main-auto-complete .ant-select-dropdown-menu-item {
  padding-left: 16px;
} */

.main-auto-complete .ant-select-dropdown-menu {
  max-height: 500px;
}
.main-auto-complete > .ant-select-selector {
  /* height: 44px !important; */
  padding: 0 !important;
}
.main-auto-complete {
  /* height: 10px; */
  /* padding: 4px; */
}
.main-auto-complete-input {
  height: 42px;
  border: none;
  border-radius: 8px;
}
