.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000;
  border-color: #dedede;
}
.ant-dropdown .ant-dropdown-menu {
  min-width: 166px;
}

.rfqDetailsWrapper {
  padding: 48px;
}
.create-mob-credit-container {
  margin-bottom: 30px;
}
