.icon-container {
  text-align: center;
  padding: 30px 30px 20px 30px;
}
.item-added {
  text-align: center;
  color: #0a243f;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.text {
  text-align: center;
  color: #0a243f;
  font-size: 12px;
  font-weight: normal;
}
