.customer-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.add_customer_btn {
    height: 46px;
    background-color: #0354a3 !important;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}
.customer_title{
    font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
}
.customerDetails-card-btn{
height: 46px;
background-color: #0354a3 !important;
font-weight: 500;






}
