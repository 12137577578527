.service-main-container {
  min-height: 400px;
  width: 100%;
  align-content: flex-start;
}
.service-first-container {
  border-bottom: 12px solid #f1f1f2 !important;
}
.service-submit-btn {
  height: 46px;
  font-weight: 500;
  background-color: #0354a3;
}
.Logistics-issue-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.dropdown-issue-wrapper {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: none;
  border-radius: 8px;
  border: solid 1px #bac7d5;
  height: 46px;
  width: 100%;
}
.service-second-container {
  padding: 20px;
  width: 100%;
  border-bottom: 12px solid #f1f1f2;
}
