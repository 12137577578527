.walletbankTransferView-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.walletbankTransferView-header-align {
  display: flex;
  gap: 24px;
  align-items: center;
}
.view-header-text {
  font-weight: 500;
  font-size: 24px;
}
.view-date-text {
  font-size: 16px;
}
.view-reject-btn {
  height: 46px;
  border: 1.5px solid #bf2600 !important;
  color: #bf2600 !important;
  font-weight: 500;
}
.view-transferred-btn {
  height: 46px;
  color: #fff !important;
  font-weight: 500;
  background-color: #0354a3 !important;
}
.request-details-notify {
    border-radius: 8px;
    background-color: #f8e6b6;
    padding: 16px;
    font-size: 14px;
    font-weight: 500;

}
.transfer-details-notify {
    border-radius: 8px;
    background-color: #e9fdf2;
    padding: 16px;
    font-size: 14px;
    font-weight: 500;

}
.view-uploader-form{

    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #eaeaea;




}
