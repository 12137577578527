.text-area-collapse-style,
.text-area-collapse-style:focus {
  border: none;
  box-shadow: none;
  resize: none !important;
}
.main-collapse {
  width: 100%;
  background-color: #fff;
}
