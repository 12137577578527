.password-change-container {
  padding: 4rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* flex-direction: column; */
  gap: 4rem;
}
.change-password-logo {
  height: 55px;
  width: 200px;
  padding-right: 10px;
}
.password-change-wrapper {
  border-radius: 16px;
  border: solid 1px #dedede;
  padding: 32px;
  /* width: 60%; */
  min-width: 350px;
  width: 70%;
  text-align: center;
  
}
.password-change-header {
    font-size: 24px;
    font-weight: 500;
}
.change-password-input{
    height: 46px;
    width: 100%;
}

.change-password-reset{
    height: 46px;
    font-weight: 500;
    margin-right: 16px;
    width: 20%;
}

.change-password-submit{
    height: 46px;
    font-weight: 500;
    background-color: #0354a3 !important;
    width: 30%;

}
.change-password-dot{
    margin-right: 8px;
     font-size: 20px;
     background-color: #000000;
     height: 20px;
     width: 20px;
     align-items:center;

}
.change-password-list{

    display: flex;
}
.change-password-tips-wrapper{
/* width: 40%; */
    border-radius: 8px;
    background-color: #f5f5f5;
padding: 32px;
}
.change-password-text{
    font-weight: 500;
}
.password-change-text-wrapper{
    display: flex;
    flex-direction: column;
}
.password-change-headerI{
    font-size: 16px;
}


@media (max-width: 1200px) {
.password-change-container{
  flex-direction: column;


    }
  }
  