.wrapper {
  padding: 2rem;
  width: 100%;
}
.heading {
  font-weight: 500;
  font-size: 24px;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contentWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.leftContent {
  width: 60%;
}
.rightContent {
  /* border: solid 1px #dedede; */
  /* border-radius: 16px; */
  /* padding: 32px; */
  width: 35%;
  /* border: solid 1px #dedede; */
  margin-top: 9.9rem;

  width: 35%;
}

.rightSubContent {
  border: solid 1px #dedede;
  /* margin-top: 8rem; */
  border-radius: 16px;
  padding: 32px;
  width: 100%;
}

/* 
:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-tab-btn {
  color: #6c7c8c;
  font-weight: 500;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #0a243f;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-ink-bar {
  background: #0a243f;
} */

/* .tab-container {
  color: #6c7c8c;
}
.tab-container:hover {
  color: #6c7c8c;
}
.tab-container
  .tablist
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab:hover {
  color: #6c7c8c;
}
.ant-tabs .ant-tabs-tab-btn:active {
  background: #0a243f;
} */
:where(.css-dev-only-do-not-override-byeoj0).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #0a243f;
}
:where(.css-dev-only-do-not-override-byeoj0).ant-tabs .ant-tabs-tab:hover {
  color: #0a243f;
}
:where(.css-dev-only-do-not-override-byeoj0).ant-tabs {
  color: #6c7c8c;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-ink-bar {
  background: #0a243f !important;
}
