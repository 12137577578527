.request-customer-card .ant-card-body {
  padding: 0;
}
.tittle-card {
  padding: 24px;
}
.request-customer-component-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 14px 16px;
  background-color: #f5f5f5;
  color: #f2f8fd;
  margin-bottom: 16px;
}
.component-avatar {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.component-text-container {
  display: flex;
  flex-direction: column;
}
.component-text-container-2 {
  display: flex;
  width: 70%;
  flex-direction: column;
}
.text-1 {
  display: block;
  color: #0a243f;
  font-size: 16px;
  font-weight: bold;
}
.text-2 {
  display: block;
  color: #6c7c8c;
  font-size: 14px;
  font-weight: normal;
}
.text-3 {
  color: #2973f0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.comment-container {
  padding: 0 24px 24px 24px;
}
.request-customer-component-2 {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #f2f8fd;
}
.component-avatar-2 {
  display: flex;
  gap: 30px;
  align-items: center;
}
.text-heading {
  display: block;
  color: #0a243f;
  font-size: 14px;
  font-weight: 500;
}

.text-color {
  display: block;
  color: #0a243f;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
}
.text-color-in {
  color: #0a243f;
  font-size: 14px;
  font-weight: 500;
}
.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.text-right
{
  text-align: right;
}
.quote-item
{
  padding: 12px 24px 12px 24px;
}
.text-heading-rfqquote
{
  font-size: 14px !important;
  font-weight: 600;
  color: #0a243f;
 
}
.rfq-heading-bmp
{
  background-color: #f5f5f5;  
  padding: 12px 12px 11px 24px;
}
.text-lightfont-rfqquote
{
  font-size: 14px;
  color: #6c7c8c;
}
.rfq-customer-card
{
  margin-top: 24px;
  padding: 0px !important;
}
.rfq-customer-card .ant-card-body 
{
  padding: 0px !important;
}