.cataloge-container-modal {
  padding: 24px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.cataloge-outer-text {
  font-size: 20px;
  display: block;
  font-weight: 500;
  text-align: center;
  color: #0a243f;
}
.cataloge-inner-text {
  font-weight: 500;
  color: #0a243f;
}
.modal-cataloge-button {
  height: 40px;
  width: 60px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  color: white;
  background-color: #bf2600;
}
.modal-cataloge-button:hover {
  color: #fff !important;
}
.cataloge-footer-container {
  display: flex;
  justify-content: center;
}
.cataloge-cancel-button {
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  max-width: 120px;
  width: 100%;
}
