.project-info-upload-btn {
  height: 46px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  background-color: #0360e5;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 100%;
}
.width-100
{
  width: 100%;
}
.project-heading, .project-icon-cell {
  gap: 16px;
  display: flex;
  align-items: center;
}
.project-header-avatar {
  border-radius: 8px;
  border: 1.5px solid #dedede;
  background-color: #b2ebb4;
  color: #0a243f;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.project-list-table
{
  white-space: nowrap;
}
.projectsearch
{
  border-radius: 32px;
    border: 1px solid #dedede;
    background-color: #fff;
    width: 100%;
    height: 48px;
}
.custom-dropdown .ant-select-selector{
  border-radius: 30px !important;
    display: flex !important;
    align-items: center !important;
}
.custom-dropdown .ant-select-selection-item,.ant-select-item-option-content
{
  display: flex;
  align-items: center;
  gap:8px
}
.custom-dropdown .ant-select-single
{
  height: 48px;
}
.card-project-address {
  margin-top: 12px;
  padding: 16px;
  border-radius: 12px;
  background-color: #f6f6f4;
}
.card-project-heading {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #0a243f !important;
}
.card-project-light {
  font-size: 12px !important;
  color: #6c7d8b !important;
}
.gap-8 {
  gap: 8px;
}
.hyperlink-text, .hyperlink-text span {
  color: #0360e5 !important;
  /* cursor: pointer; */
}
.justify-content-center {
  justify-content: center !important;
}
.card-project-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #0a243f !important;
}
.card-project-textlight {
  font-size: 14px;
  color: #6c7c8c !important;
}
.map-wrapper {
  gap: 8px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-decoration: underline;
}
.d-flex
{
  display: flex !important;
}
.justify-content-space {
  justify-content: space-between !important;
}
.card-projects {
  height: 100%;
  border-radius: 16px !important;
  border: 1.5px solid #dedede !important;
}
.card-projects .ant-card-body{
  padding: 16px !important;
}
.sectionDivider {
  width: 100%;
  height: 16px;
  background-color: #f1f1f2;
}
.bg-gradient-section {
  background-image: linear-gradient(146deg, #fdf1ec 28%, #fae6bf 132%);
}
.inputSectionFirst {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}
.text-project-add-caption {
  font-size: 18px;
  font-weight: 600;
  color: #0a243f;
}
.btn-address-select {
  height: 52px;
  border-radius: 16px;
  border: 1.5px solid #dedede;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.71;
  text-align: center;
  color: #2973f0;
}
.editAddressInfoModalParent .ant-modal-body {
  max-height: 470px;
  overflow-y: auto;
}
.addressInfoModalParent .ant-modal-header, .editAddressInfoModalParent .ant-modal-header {
  padding: 24px !important;
  margin-bottom: 0;
}
.address-mdoal-input-label .inputLabelParent {
  gap: 6px;
}
.address-mdoal-input-label {
  font-size: 14px;
  text-align: left;
  color: #0a243f;
}
.editAddressFooterParent {
  justify-content: center;
  padding: 16px 24px;
  border-top: 1px solid #dedede;
}
.editAddressFooterButton {
  width: 138px;
  height: 52px;
  border-radius: 32px;
  border: 1px solid #9da7b2;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: center;
  color: #0a243f;
}
.editFooterSaveButton {
  width: 222px;
  height: 52px;
  border-radius: 32px;
  background-color: #0360e5;
  color: #fff;
}
.user-add-address {
  /* width: 100%; */
  margin: 0 0 16px;
  padding: 12px;
  border-radius: 16px;
  border: 1.5px solid #dedede;
  cursor: pointer;
}
.address-mdoal-input-label .inputLabelParent .redStar {
  color: red;
}
.addressInputContentParent {
  display: flex;
  width: 100%;
  gap: 14px;
}
.address-mdoal-input-label .inputLabelParent {
  gap: 6px;
}
.addressInputContentParent>div {
  width: 50%;
}
.card-project-textlight-list {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #0a243f !important;
}
.mt-2
{
margin-top: 8px;
}
.text-danger-na
{
  color: #d33f0d;
}
.request-price-inp-err, .request-price-inp-err-select .ant-select-selector {
  border: 1px solid red !important;
  background-color: #f3e3e3 !important;
}
.modifiedStateContainer .ant-select-selector {
  border: 1px solid red !important;
}
.modifiedStateContainer .ant-select-selection-search {
  background-color: #f3e3e3 !important;
}
.card-project-address.fixed-height
{
  height: 230px;
}
.map-container
{
  display: flex;                  
  flex-direction: column;        /* Stack children vertically */
  justify-content: flex-end;
}
.addres-line-fix
{
  display: -webkit-box;          /* Use flexbox */
  -webkit-box-orient: vertical; /* Set orientation */
  -webkit-line-clamp: 3;        /* Limit to 3 lines */
  overflow: hidden;              /* Hide overflow */
  text-overflow: ellipsis;
}
.no-result-found-container {
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 290px;
}
.no-result-found-container-txt {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #0a243f;
  margin-top: 24px;
}
.no-result-found-container-txt-please-try {
  font-size: 14px;
  line-height: 2.14;
  text-align: center;
  color: #0a243f;
  margin-top: 8px;
}
.cursor-pointer
{
  cursor: pointer;
}

.project-tag
{
  padding: 4px 12px 3px;
  border-radius: 8px;
  background-color: #ffd911;
  color: #0a243f;
  font-size: 14px;

}