.upload-list {
  width: 100%;
}
.upload-list > .ant-upload,
.ant-upload-select {
  width: 100%;
}

.style-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}
.cross-wrapper {
  width: 30px;
  padding: 7px 9px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}
.styled-avatar {
  width: 70px;
  height: 70px;
  position: relative;
}
.list-wrapper {
  display: flex;
  width: 100%;
  gap: 3rem;
  flex-wrap: wrap;
}
.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
