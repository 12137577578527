.wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.btn {
  height: 46px;
  font-weight: 500;
  max-width: 111px;
}
.header-text {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 4px;
}
.header-textI {
  font-weight: 500;
  color: #6c7c8c;
  margin-top: 4px;
}
.order-del {
  display: flex;
  flex-direction: column;
}
.detail-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.btn-wrapper {
  display: flex;
  gap: 1rem;
}
.edit-btn {
  height: 46px;
  padding: 0 24px;
  font-weight: 500;
}
.cncl-btn {
  height: 46px;
  padding: 0 24px;
  font-weight: 500;
  color: #bf2600;
  border-color: #bf2600;
}

.align-text {
  display: flex;
  align-items: center;
  gap: 8px;
}
.profile-wrapper {
  width: 36.5%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.profile {
  padding: 24px;
  border-radius: 16px;
  border: solid 1px #dedede;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.body-wrapper {
  display: flex;
  justify-content: space-between;
}
.profile-name {
  display: flex;
  align-items: center;
  gap: 16px;
}
.contact {
  display: flex;
  flex-direction: column;
}
.add-gst {
  padding: 1rem;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-body {
  width: 60.5%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.item-container {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.item-text {
  font-size: 20px;
  font-weight: 500;
}
.detail-notify {
  padding: 16px;
  border-radius: 8px;
  background-color: #ffebe6;
  display: flex;
  gap: 12px;
  font-weight: 500;
}
.detail-notify-cancelled {
  padding: 16px;
  border-radius: 8px;
  background-color: #ffebe6;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.detail-notify-cancelled-text {
  font-size: 12px;
  font-weight: 500;
}
