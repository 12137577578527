.rfq-table table tbody tr td:first-child {
  border-left: none;
  border-right: none;
}
.rfq-table .ant-table-footer {
  background-color: #fff;
}
.col-delete-icon:hover {
  background-color: #ffd7d5;
}

.custom-textarea:hover {
  border: solid 1px #0a243f !important;
}
.custom-textarea:focus {
  box-shadow: 0 0 0 2px rgb(10 36 63 / 10%);
}
.custom-card .ant-card-body {
  padding: 0 !important;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-card .ant-card-body::before {
  content: none !important;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-card .ant-card-body::after {
  content: none !important;
}

.ant-table-thead > tr > td {
  width: 18%;
}

.custum_input {
  border: 1px solid #fff;
}

.custum_input:hover {
  border: 1px solid #2973f0 !important;
}
.custum_input_ddl
{
  width: 50% !important;
  height: unset !important;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover {
  background-color: #fff !important;
}
.toggle_input .ant-input {
  text-align: right;
}
/* .ant-popover-inner {
  width: 200px;
} */
/* .ant-popover-content {
  right: 40px;
  padding: 1rem !important;
} */
/* :where(.css-dev-only-do-not-override-txh9fw).ant-popconfirm
  .ant-popconfirm-buttons {
  text-align: center;
} */
:where(.css-dev-only-do-not-override-txh9fw).ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: none;
  border: solid 2px #0a243f;
}
:where(
    .css-dev-only-do-not-override-txh9fw
  ).ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item-icon {
  margin-top: 28px;
}
:where(
    .css-dev-only-do-not-override-txh9fw
  ).ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item-process
  .ant-steps-item-icon {
  margin-top: 28px;
}
:where(
    .css-dev-only-do-not-override-txh9fw
  ).ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item-icon {
  margin-top: 28px;
}

:where(
    .css-dev-only-do-not-override-txh9fw
  ).ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  margin-top: 15px;
}

/* .ant-popover-inner {
  width: auto;
  padding: 0 !important;
} */

/* .custum_popover .ant-popover-inner {
  padding: 10px !important;
} */

/* .input_class,
.input_class:focus,
.input_class:active,
.input_class:hover {
  border: 2px solid #0a243f !important;
} */

.error_class,
.error_class:focus,
.error_class:active,
.error_class:hover {
  border: 1px solid red !important;
}
.discount_error_input {
  border: 1px solid red;
}
.discount_error_input:hover,
.discount_error_input:active,
.discount_error_input:focus {
  border: 1px solid red;
}
.before_tax_input {
  width: 50% !important;
}

.rfq-table .ant-table-cell
{
  padding: 8px !important;
}

.rfq-table.tbl-disabled
{
  border: 2px solid red !important;
}