.deliveryBy-table {
  font-size: 16px;
  font-weight: 500;
  border-radius: 16px;
  border: solid 1px #dedede;
}
.deliveryBy-wrapper {
  display: flex;
  gap: 24px;
  width: 70%;
  align-items: center;
}
.deliveryBy-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 380px;
}
.deliveryBy-calc {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #ffff;
  border-bottom: 1px solid #dedede;
  padding: 16px;
}
.deliveryBy-align-amount {
  display: flex;
  justify-content: space-between;
}
.deliveryBy-align-total {
  background-color: #ffff;
  display: flex;
  border-bottom: 1px solid #dedede;
  justify-content: space-between;
  padding: 16px;
}
.deliveryBy-text {
  font-weight: bold;
  font-size: 20px;
}
.tax-info {
  display: flex;
  gap: 8px;
  align-items: center;
}
.return-exchange-notify {
  display: flex;
  padding-top: 1rem;
  align-items: center;
  gap: 12px;
}
.status-pointer {
  height: 12px;
  width: 12px;
  border-radius: 6px;
}
.delivery-status-notify {
  color: #6c7c8c;
  font-size: 14px;
  font-weight: 400;
}
.styled-btn{
width: 110px;
border-radius: 20px;
font-weight: 500;
}
.btn-containerI{

  display: flex;
  gap: 8px;
}