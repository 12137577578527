.address-wrapper {
  /* padding: 24px; */
  border-radius: 16px;
  border: solid 1px #dedede;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0 24px;
}
.edit-btnn {
  color: #2973f0;
  font-weight: 500;
  cursor: pointer;
}
.body {
  display: flex;
  flex-direction: column;
  max-width: 260px;
  padding: 12px 0px 16px 24px;
}
.gst {
  font-weight: 500;
  color: #ffffff;
  margin: 12px 0;
  height: auto;
  border-radius: 8px;
  background-color: #133b62;
  width: 100%;
  padding: 8px 16px 7px;
}
.gst-btn {
  color: #2973f0;
  font-weight: 500;
  cursor: pointer;
}
.bottom-banner {
  background-color: #f2f2f2;
  padding: 24px;
  border-radius: 0 0 16px 16px;
}
