.dashboard-table .ant-table-row-expand-icon-cell {
  width: 0 !important;
}

.dashboard-table table tbody tr td:first-child {
  border-right: none;
}

.dashboard-table .ant-table-expanded-row .ant-table-cell {
  background-color: #ffffff;
  padding: 16px 30px 16px 48px;
}

.cursor {
  cursor: pointer;
}
.dashboard-card-align {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 32px;
}

.dashboard-content-card {
  width: 48.5%;
  height: 440px;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.dashboard-table-orderid {
  font-size: 16px;
  font-weight: 400;
  color: #2973f0;
  cursor: pointer;
}
.dashboard-main-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  background-color: #f1f1f2;
  padding: 32px;
}
.dashboard-main-row-container {
  justify-content: space-between;
}
.dashboard-main-row-card-container {
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}
.status-card-container {
  padding: 16px;
  width: 22.8%;
  height: 120px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
.status-card-container-text {
  font-weight: 500;
  font-size: 15px;
}
.status-card-container-text-color {
  font-weight: 500;
  color: #6c7c8c;
}
.skeleton-wrapper-container {
  height: 400px;
  width: 100%;
}
.dashboard-table-container {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  margin-bottom: 30px;
}
.dashboard-table-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-table-title-text-container {
  font-size: 20px;
  font-weight: 500;
  width: 80%;
}
.dashboard-table-title-text-view-container {
  font-weight: 500;
  cursor: pointer;
  color: #2973f0;
}
@media (max-width: 1450px) {
  .dashboard-card-align {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .dashboard-content-card {
    width: 100%;
  }
}

.highlight-row, .highlight-row .ant-table-cell-fix-left , .highlight-row .ant-table-row {
  background-color: #ffebe6 !important;
  
}
.highlight-row td.ant-table-cell-row-hover
{
  background: #ffebe6 !important; 
}

.highlight-row td{
  border-bottom:   1px solid #bf2600 !important;
  border-top:   1px solid #bf2600 !important;
}