.myAccOrderDetailsMainParent {
  width: 80%;
  margin: 0 auto;
}

.myAccOrderDetailsBackButtonParent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22%;
  height: 48px;
  border-radius: 32px;
  background-color: #0354a3;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  gap: 12px;
}

.myAccTableAddressParent {
  width: 100%;
  flex-direction: row;
}

.myAccountOrderDetailsBreadCrums .breadcrum-container {
  margin: 28px 0 0;
}

.myAccOrderDetailsTableContentParent {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  height: auto;
  width: 100%;
  flex-direction: row;
}

.myAccOrderDetailsAddressParent {
  width: 33%;
  flex-direction: column;
  gap: 24px;
}

.responsive-cart-checkout-details-parent {
  display: none;
}

.my-account-order-details-user-gst-number {
  height: 32px;
  border-radius: 8px;
  background-color: #133b62;
  align-items: center;
  padding: 0 16px;
  width: fit-content;
}

.myAccProductCategoryItemsHeadingResponsiveParent {
  width: 100%;
}

.myAccountImageFullScreenParent {
  display: block;
}

.my-account-order-details-user-gst-number-content {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.myAccOrderDetailsOrderIdParent {
  justify-content: space-between;
  align-items: flex-end;
  gap: 40px;
}

.myAccOrderDetailsOrderIdContentParent {
  gap: 16px;
  flex-direction: column;
}

.myAccOrderDetailsOrderTitle {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.enabled-responsive-button {
  display: none;
}
.myAccProductCategoryImagesParentResponsive {
  display: none;
}

.myAccOrderDetailsOrderId {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #6c7c8c;
}

.myAccOrderDetailsContactUsTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #0a243f;
}

.myAccOrderDetailsContactUsButton {
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  gap: 16px;
  padding: 0 10px;
  height: 48px;
}

.myAccOrderDetailsNote {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.my-account-selected-products-parent {
  display: none;
}

.myAccOrderDetailsNoteParent {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background-color: #f1f1f2;
  display: block;
}

.my-account-responsive-forgot-address-parent {
  display: none;
}
.myAccOrderDetailsTableParent {
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border: 16px;
  height: auto;
  gap: 32px;
  margin-bottom: 88px;
}

.myAccOrderDetailsTableHeader {
  height: 100px;
  width: 100%;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}

.myAccOrderDetailsTableImageContentParent {
  width: 56px;
  height: 56px;
  padding: 12px;
  border-radius: 16px;
  background-color: #f8e6b6;
}

.myAccOrderDetailsTableTextContentParent {
  flex-direction: column;
  gap: 12px;
}

.myAccOrderDetailsTableTextTitleParent {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccOrderDetailsTableTextValueParent {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0a243f;
}

.myAccOrderDetailsTableImageParent {
  gap: 16px;
}
.myAccOrderDetailsTableHeaderButtonParent {
  flex-wrap: nowrap;
  gap: 16px;
}

.myAccOrderDetailsTableHeaderButton {
  width: 70%;
  height: 40px;
  padding: 12px 16px 11px;
  border-radius: 32px;
  border: solid 1.5px #dedede;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  gap: 12px;
  letter-spacing: normal;
  color: #0a243f;
}

.myAccProductCategoryItemsParent {
  padding: 24px;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  border-bottom: 1px solid #d9d9d9;
}

.myAccProductCategoryItemsParent:last-child {
  border: none;
}

.myAccProductCategoryItemsHeadingParent {
  width: 100%;
  justify-content: space-between;
}

.myAccProductCategoryItemsHeadingParent .myAccOrderDetailsTableHeaderButton {
  width: 17%;
}

.myAccOrderDetailsTableTextShipment {
  color: #4fb589;
}

.myAccProductCategoryImagesParent {
  gap: 16px;
}

.myAccProductCategoryTimelineParent {
  flex-direction: row;
  width: 100%;
  height: 30px;
  margin-left: 105px;
}

.myAccProductCategoryTimelineParent .ant-timeline {
  width: 100%;
  padding-left: 110px;
}

.myAccProductCategoryTimelineParent .ant-timeline .ant-timeline-item {
  width: 35%;
  padding-bottom: 0;
  padding-left: 0;
}

.myAccProductCategoryTimelineParent .ant-timeline {
  display: flex;
}

.myAccProductCategoryTimelineParent .customTailColor .ant-timeline-item-tail {
  height: 5px;
  background-color: transparent;
  border-color: #4fb589;
  right: 0;
  padding: 0px !important;
}

.myAccOrderDetailsTimelineParent .ant-timeline-item .ant-timeline-item-tail {
  transform: rotateZ(90deg);
  height: 187px;
  position: relative;
  top: 9%;
  left: 0;
  background-color: transparent;
}

.myAccOrderDetailsTimelineParent .defaultTailColor {
  padding-left: 75px;
}
.myAccOrderDetailsTimelineParent .customTailColor {
  padding-left: 70px;
}

.myAccProductCategoryTimelineLabelParent {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.myAccOrderDetailsTableTextt {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #6c7c8c;
}

.myAccProductCategoryTimelineLabel {
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.myAccProductCategoryTimelineLabel {
  width: 100%;
}

.myAccOrderDetailsDeliveryAddressParent {
  width: 100%;
  min-height: auto;
  max-height: auto;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 16px;
  flex-wrap: nowrap;
  border: solid 1px #dedede;
}

.myAccOrderDetailsTimelineParent {
  padding: 0 !important;
  align-content: flex-end;
}

.my-acc-order-detail-timeline-parent-responsive {
  display: none;
}

/* .myAccProductCategoryTimelineParent .customTailColor .ant-timeline-item-head{
      
      right: 50px;
    }
    
    .myAccProductCategoryTimelineParent .defaultTailColor .ant-timeline-item-head{
      right: 50px;
    
    } */

/* .myAccProductCategoryTimelineParent .customTailColor .ant-timeline-item-head:first-child{
      left: 0;
    } */
.myAccOrderDetailsTimelineParent .defaultTailColor .ant-timeline-item-head {
  top: 4px;
  right: 100px;
  position: absolute;
  left: 0;
}

.myAccOrderDetailsTimelineParent .customTailColor .ant-timeline-item-head {
  position: absolute;
  left: 0;
  border-width: 9px !important;
  top: 4px;
  right: 100px;
}

.myAccDetailSingleSelect .ant-timeline-item-head {
  top: 4px;
  right: 100px;
  border-width: 9px;
}

.myAccDetailSingleSelect {
  padding-left: 75px;
}

.myAccDetailSingleSelect .ant-timeline-item-tail {
  position: relative;
  top: 4.5% !important;
}

/* .myAccProductCategoryTimelineParent .ant-timeline .ant-timeline-item .ant-timeline-item-head {
      background-color: #d9d9d9 !important;
    } */

.myAccOrderDetailsTimelineDotImages {
  position: absolute;
  left: 100px;
  border-radius: 20px;
  border: 2px solid #4fb589;
}

.my-account-order-details-product-parent {
  width: 65%;
  gap: 32px;
}

.my-account-order-details-main-nested-parent {
  gap: 24px;
}

.my-account-order-details-user-address-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.my-account-order-details-user-address {
  width: 36%;

  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccOrderDetailsOrderIdParentResponsive {
  flex-direction: column;
  gap: 8px;
}

.my-acc-order-detail-timeline-responsive {
  display: none;
}

@media only screen and (max-width: 650px) {
  .myAccOrderDetailsBackButtonParent {
    display: none;
  }

  .myAccOrderDetailsOrderTitleResponsive {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
  }

  .myAccOrderDetailsOrderIdResponsive {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #6c7c8c;
  }

  .myAccOrderDetailsOrderIdParentResponsive {
    gap: 2px;
    flex-direction: column;
  }

  .myAccOrderDetailsContactUsButtonResponsive {
    display: none;
  }

  .my-account-order-details-product-parent-responsive {
    width: auto;
    margin: 0 16px;
  }

  .myAccOrderDetailsTableHeaderResponsive {
    padding: 16px;
    gap: 16px;
    height: auto;
  }

  .myAccOrderDetailsTableHeaderButtonParentResponsive {
    width: 100%;
    justify-content: space-between;
  }

  .myAccOrderDetailsTableHeaderButtonParentResponsive
    .myAccOrderDetailsTableHeaderButton {
    width: auto;
  }

  .myAccProductCategoryItemsHeadingParent .myAccOrderDetailsTableHeaderButton {
    display: none;
  }

  .myAccProductCategoryTimelineParentResponsive {
    display: none;
  }

  .myAccProductCategoryTimelineLabelParent {
    display: none;
  }

  .my-acc-order-detail-timeline-responsive {
    display: flex;
  }

  .my-acc-order-detail-timeline-responsive .ant-timeline {
    width: 100%;
  }

  .my-acc-order-detail-timeline-responsive ul .singleSelect {
    height: 25% !important;
  }

  .my-acc-order-detail-timeline-responsive
    ul
    .singleSelect
    .ant-timeline-item-head {
    height: 14px !important;
    border-color: #4fb589 !important;
    width: 14px !important;
    border-width: 7.5px !important;
  }

  .my-acc-order-detail-timeline-responsive
    ul
    .singleSelect
    .ant-timeline-item-tail {
    margin-left: 1px;
    border-width: 4px !important;
  }

  .my-acc-order-detail-timeline-responsive ul .customTailColor {
    height: 25% !important;
  }

  .my-acc-order-detail-timeline-responsive
    ul
    .customTailColor
    .ant-timeline-item-head {
    height: 14px !important;
    width: 14px !important;
    border-width: 7.5px !important;
    border-color: #4fb589 !important;
  }

  .my-acc-order-detail-timeline-responsive
    ul
    .customTailColor
    .ant-timeline-item-tail {
    margin-left: 1px;
    border-color: #4fb589 !important;
    border-width: 4px !important;
  }

  .my-acc-order-detail-timeline-responsive ul .defaultTailColor {
    height: 25% !important;
  }

  .my-acc-order-detail-timeline-responsive
    ul
    .defaultTailColor
    .ant-timeline-item-head {
    height: 14px !important;
    width: 14px !important;
    border-width: 7px !important;
  }

  .my-acc-order-detail-timeline-responsive
    ul
    .defaultTailColor
    .ant-timeline-item-tail {
    margin-left: 1px;
    border-width: 4px !important;
  }

  .timelineInnerContentNameResponsive {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #0a243f;
  }

  .timelineInnerContentTimeResponsive {
    font-family: Inter;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.18;
    letter-spacing: normal;
    color: #6c7c8c;
  }

  .timelineInnerContent {
    justify-content: space-between;
  }

  .myAccProductCategoryItemsParent {
    padding: 0px;
    gap: 0;
  }

  .my-acc-order-detail-timeline-parent-responsive {
    display: block;
    border: none;
  }
  .my-acc-order-detail-timeline-parent-responsive .ant-collapse-item {
    border: none;
  }

  .my-acc-order-detail-timeline-parent-responsive
    .ant-collapse-item
    .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
    background-color: white;
    width: auto !important;
    width: 115px;
    border-radius: 16px;
  }

  .my-acc-order-detail-timeline-parent-responsive
    .ant-collapse-item
    .ant-collapse-content {
    border-radius: 0 0 16px 16px;
  }

  .my-acc-order-detail-timeline-parent-responsive
    .ant-collapse-item
    .ant-collapse-content-box {
    border-radius: 0 0 16px 16px;
    padding-bottom: 0;
  }

  .my-acc-order-detail-timeline-headerText-responsive {
    width: 50px !important;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #0a243f;
  }

  .myAccProductCategoryItemsHeadingResponsiveParent {
    gap: 24px;
    border-bottom: 1px solid #dedede;
    padding: 16px;
    height: auto;
  }

  .myAccOrderDetailsMainParent {
    width: 100%;
  }

  .myAccOrderDetailsAddressParent {
    width: 100%;
  }

  .myAccOrderDetailsTableParent {
    gap: 16px;
  }

  .my-account-responsive-items-content-parent {
    justify-content: space-between;
  }

  .my-account-responsive-items-parent {
    width: 100%;
    height: 200px;
  }

  .my-account-order-details-user-address-title-responsive {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
  }
  .my-account-order-details-user-address-billername-responsive {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #0a243f;
    width: 90%;
  }

  .my-account-order-details-user-address-responsive {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #0a243f;
    width: 90%;
  }

  .myAccOrderDetailsDeliveryAddressParent {
    border: none;
    padding: 0 16px;
  }

  .myAccOrderDetailsOrderIdParent {
    padding: 0 16px;
  }

  .myAccOrderDetailsNoteParent {
    margin: 0 16px;
    width: auto;
  }

  .my-account-responsive-forgot-address-parent {
    display: flex;
    height: 50px;
    width: auto;
    background-color: #f8e6b6;
    border-radius: 8px;
    margin: 0 16px;
    padding: 0 16px;
    flex-wrap: nowrap;
    align-items: center;
  }

  .responsive-cart-checkout-details-parent {
    display: block;
    width: 100%;
  }

  .order-details-list-text {
    margin-right: 12px;
    font-family: Inter;
    font-size: 12px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
  }

  .order-details-list-text-value {
    font-family: Inter;
    font-size: 12px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: right;
    color: #0a243f;
  }

  .order-details-list-text-discount-value {
    color: #01a685 !important;
  }

  .responsive-cart-checkout-details-parent .order-details-description-checkout {
    padding-top: 0;
  }

  .myAccProductCategoryImagesParentResponsive {
    display: block;
  }
  .responsive-myAcc-order-image {
    display: none;
  }

  .myAccountImageFullScreenParent {
    display: none;
  }

  .enabled-responsive-button {
    display: flex;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dedede;
    border: none;
    color: #6c7c8c;
  }

  .my-account-selected-products-parent {
    display: block;
    width: 100%;
    padding: 0 16px;
  }

  .my-account-item-button-parent {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .my-account-item-button-parent button {
    width: 35%;
    height: 32px;
    border-radius: 32px;
    border: solid 1.5px #dedede;
    background-color: #fff;
    font-weight: 500;
  }

  .rendered-image-parent {
    gap: 16px;
  }
}
.myAccountCardParent {
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.myAccountBreadCrums {
  margin-bottom: 20px;
}

.my-account-chevron-responsive {
  display: none;
}

.myAccountProfileParent {
  width: 100%;
  height: 136px;
  padding: 24px;
  background-color: #f1f1f2;
  justify-content: space-between;
  border-radius: 16px;
}

.myAccountCardProfilePhotoContentParent {
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.myAccountCardProfilePhotoSection {
  height: auto;
  gap: 32px;
  width: 40%;
}

.myAccountUserProfileImage {
  border: 16px;
}

.myAccountCardTotalCardParent {
  gap: 16px;
  width: 30%;
  justify-content: flex-end;
}

.myAccountCardProfileRewardTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
  margin: 0 32px 0 8px;
}

.myAccountUsername {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountCardMainRowItemsContent {
  gap: 16px;
}
.myAccountCardTotalOrderParent {
  height: 88px;
  width: 42%;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 16px;
  align-items: flex-end;
  justify-content: center;
  padding-right: 12px;
}

.myAccountCardTotalOrderTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountCardMainProductRightArrowResponsive {
  display: none;
}

.myAccountMainTableContentParentResponsive {
  width: 68%;
  gap: 15px;
}

.myAccountCardTotalOrderValue {
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountResellTableResponsive {
  display: none;
}

.myAccountCardMainBodyParent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.myAccountCardMainBodyParent ul {
  width: 30%;
}

.myAccountCardMainProductHeadingParentResponsive {
  display: none;
}
.myAccountCardTabsParent {
  width: 100%;
}

.myAccountCardTabsParent .ant-tabs {
  width: 100%;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-tabs {
  color: #0a243f !important;
}

.myCardMainMenuParent {
  width: 30%;
  height: auto;
  margin-top: 30px;
}

.myAccountCardMainRowItems {
  width: 100%;
  gap: 16px;
  color: #0a243f;
  height: 100%;
  align-items: center;
  padding-left: 16px;
}

.myAccountCardMenuName {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.my-acc-order-details-responsive-filter-button {
  display: none;
}

.myCardMenuItemsContainer {
  height: 56px !important;
  display: flex;
  align-items: center;
}

.myCardMenuItemsContainer:hover {
  padding: 0 !important;
  background-color: #0a243f !important;
}
/* .myAccountCardMainRowItems:hover {
    color: #fff !important;
  } */

.myAccountCardMainRowItems:hover .myAccountCardMenuName {
  color: white;
}

.myAccountMainTableHeadingParent {
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin: 0 0 0;
  width: 100%;
}

.responsiveAccOrderDetailsTag {
  display: none;
}

.myAccountTableTitle {
  font-size: 24px;
  font-weight: 500;
}

.my-account-responsive-divider {
  display: none;
}
.myAccountTableTotalItems {
  opacity: 0.6;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountCardInput {
  min-width: 200px;
  max-width: 300px;
  height: 40px;
  padding: 11px 24px 12px;
  border-radius: 32px;
  border: solid 1px #dedede;
  background-color: #fff;
}

.myAccountHeadingTitleParent {
  align-items: baseline;
  gap: 12px;
}

.myAccountSortOrder {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountMainTableContentParent {
  display: block;
  width: 67%;
  height: auto;
  gap: 33px;
}

.myAccountCardInputWithFilterParent {
  flex-wrap: nowrap;
  width: 55%;
  gap: 32px;
  display: flex;
  height: 46px;
  align-items: center;
}

.myAccountSortOrderContentParent {
  /* width: 70%; */
  align-items: center;
  gap: 1rem;
  display: flex;
  width: 430px;
  /* justify-content: space-between; */
}

.myAccountCardTableData {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 16px;
  align-content: flex-start;
}

.myAccountTableHeadingContentParent {
  padding: 24px;
  width: 100%;
  justify-content: space-between;
  height: 90px;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
}

.myAccountTableHeading {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #6c7c8c;
}

.myAccountTableValue {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountTabelHeadingTotal {
  opacity: 0.6;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountTabelHeadingTotal {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: right;
  color: #0a243f;
}

.myAccountTableHeadingOrderId {
  flex-direction: column;
}
.order-rfq-main-container {
  padding: 18px;
  background-color: #f1f1f2;
  border-radius: 16px 16px 0 0;
  width: 100%;
}
.order-rfq-main-container-text-heading {
  color: #0a243f;
  font-size: 14px;
  font-weight: 500;
}
.order-rfq-main-container-text {
  color: #0a243f;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  text-decoration: underline;
  padding-left: 4px;
}

.myAccountTableHeadingParent {
  gap: 32px;
}

.myAccountTableHeadingTotalPrice {
  gap: 8px;
}

.myAccountArrivalDate {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountShipment {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}

.myAccountCardMainProductHeadingParent {
  gap: 12px;
  flex-direction: column;
}

.myAccountCardMainProductDetails {
  padding: 24px;
  justify-content: space-between;
  width: 100%;
}

.myAccountGst {
  display: flex;
  opacity: 0.7;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: right;
  color: #0a243f;
}

.myAccountCardMainProductArrivalParent {
  width: 100%;
  justify-content: space-between;
}

.myAccountButtonParent {
  gap: 16px;
  flex-direction: column;
}

.myAccountShipmentButton {
  width: 232px;
  height: 52px;
  color: #fff;
  border-radius: 32px;
  font-weight: bold;
  background-color: #0354a3;
}

.myAccountOrderDetailButton {
  width: 232px;
  height: 52px;
  border: 1px solid #dedede;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a243f;
  font-weight: bold;
  background-color: #fff;
}

.myAccountImageWithButtonParent {
  width: 100%;
  justify-content: space-between;
}

.myAccountImage {
  gap: 16px;
}

.myAccountRewardPointsParent {
  align-items: center;
  gap: 6px;
}

.myAccountUserProfileWithStarParent {
  position: relative;
}

.myAccTabsText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #0a243f;
}

.ant-tabs-nav-list .ant-tabs-tab {
  padding: 15px 25px 17px;
}

/* .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-active{
        padding: 15px 25px 17px;
  
      } */

/* .myAccTabsParent .ant-tabs-nav {
        border-color: transparent;
      } */

/* .myAccTabsParent .ant-tabs-tab-active {
        border-bottom: 6px solid red; 
        position: relative
      } */
:where(.css-dev-only-do-not-override-pr0fja).ant-tabs .ant-tabs-ink-bar {
  background: #0354a3 !important;
}

.myAccMoreImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f2;
  height: 64px;
  width: 64px;
  border-radius: 16px;
}
.myAccMoreImageI {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f2;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  margin-right: 1rem;
}

.myAccOwnerSection {
  height: auto;
  width: 100%;
  background-color: #f1f1f2;
  justify-content: space-between;
  padding: 8px 24px 8px 6px;
  border-radius: 16px 16px 0 0;
}

.myAccOwnerHouse {
  width: 30%;
  height: 40px;
  background-color: #4fb589;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
}

.myAccOwnerHouse .myAccountTableValue {
  color: #fff;
}

.myAccOwnerAccess {
  align-items: center;
  justify-content: center;
}

.myAccountResellTable {
  background-color: #5b7fc1;
  width: 30%;
  height: 40px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
}

.myAccountResellTable .myAccountTableValue {
  color: #fff;
}

.myAccGetRewardPoints {
  gap: 4px;
  align-items: center;
}

.myAccountOrderRateItemButton {
  width: 232px;
  height: 52px;
  border-radius: 32px;
  color: #0a243f;
  font-weight: bold;
  background-color: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media only screen and (max-width: 650px) {
  .myAccountCardParent {
    width: 100%;
    display: none;
  }

  .myAccountProfileParent {
    width: 95%;
    margin: 0 auto;
    flex-wrap: nowrap;
    flex-direction: column;
    min-height: 168px;
    padding: 16px;
    margin-bottom: 16px;
  }

  .myAccountUserProfileWithStarParent {
    height: 56px;
    width: 56px;
  }
  .my-account-shinning-responsive {
    height: 24px;
    width: 24px;
  }

  .myAccountMainTableContentParent {
    display: block;
    width: 100%;
  }

  .myCardMainMenuParent {
    display: block;
    width: 100% !important;
    margin-top: 0;
  }

  .myAccountCardMainBodyParent ul {
    width: 100%;
  }

  .myAccountCardTotalCardParent {
    flex-wrap: inherit;
    width: 100%;
  }

  .myAccountCardTotalOrderParent {
    height: 62px;
    width: 50%;
    align-items: center;
    flex-wrap: inherit;
    gap: 9px;
    margin-top: 18px;
  }

  .myAccountCardTotalOrderTitle {
    font-family: Inter;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #0a243f;
  }

  .location-left {
    max-width: 90%;
  }

  .myAccTabsText {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #6c7c8c;
    align-items: center;
  }

  .myAccountCardTabsParent {
    width: 95%;
    margin: 0 auto;
  }

  .ant-tabs-tab {
    justify-content: center;
  }
  .myAccTabsParent
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab-active {
    background-color: #0a243f !important;
    border-radius: 8px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    justify-content: center;
  }

  .myAccountCardProfilePhotoContentParent {
    gap: 9px;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    width: 50%;
  }

  .myAccTabsParent
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab-active
    .ant-tabs-tab-btn
    span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #fff;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .myAccountCardProfilePhotoSection {
    gap: 28px;
    width: 100%;
  }

  .myAccountUserProfileImage {
    height: 56px;
    width: 56px;
  }

  .myAccountCardMainRowItems {
    border-bottom: 1px solid #dedede;
    padding-left: 0;
  }

  .my-account-chevron-responsive {
    display: block;
  }

  .myAccountCardMainRowItemsContent {
    gap: 16px;
  }

  .myAccountCardMainRowItems {
    justify-content: space-between;
  }

  .myAccountMainTableHeadingParent {
    width: 100%;
    flex-direction: column;
    align-items: start;
    margin: 0;
  }

  .myAccountTableTotalItems {
    display: none;
  }

  .myAccountCardInputWithFilterParent {
    width: 100%;
    gap: 8px;
    padding: 0 16px;
  }

  .my-acc-order-details-responsive-filter-button {
    display: flex;
    width: 50%;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 32px;
    border: solid 1.5px #dedede;
    background-color: #fff;
  }

  .my-account-responsive-divider {
    display: block;
    width: 100%;
  }

  .my-account-responsive-divider .cartSectionDivider {
    width: 100%;
  }

  .myAccountTableHeadingOrderIdResponsive {
    display: none !important;
  }

  .myAccountTableHeadingOrderId {
    flex-direction: row;
    gap: 8px;
  }

  .myAccountTableHeadingParent {
    flex-direction: column;
    gap: 8px;
  }

  .myAccountTableHeadingTotalPrice {
    flex-direction: column;
  }

  .myAccountTabelHeadingTotalResponsive {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    height: auto;
    letter-spacing: normal;
    text-align: left;
    color: #6c7c8c;
  }

  .myAccountCardTableData {
    border: none;
  }

  .myAccountCardMainProductHeadingParent {
    display: none;
  }

  .myAccountCardMainProductHeadingParentResponsive {
    display: flex;
    width: 90%;
  }

  .myAccountImageWithButtonParentResponsive {
    flex-wrap: initial;
    gap: 16px;
    width: 100%;
  }

  .myAccountImageResponsive {
    height: 32px;
    width: 32px;
  }

  .myAccountHeadingTitleParent {
    margin-left: 16px;
  }

  .myAccountImageResponsiveParent {
    height: auto;
    margin-top: 10px;
    flex-wrap: wrap;
    /* gap: 8px; */
    flex-direction: row;
    /* min-width: 25%; */
    /* gap: 8px; */
    /* max-width: 33%; */
  }

  .myAccTabsParent {
    display: none;
  }

  .myAccountArrivalDateResponsive {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    height: auto;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
  }

  .myAccountCardMainProductRightArrowResponsive {
    display: block;
    margin-top: 20px;
  }

  .myAccountGst {
    display: none;
  }

  .myAccountButtonParent {
    margin: 20px 0;
    width: 100%;
  }

  .myAccountButtonParent button {
    width: 100%;
  }

  .myAccountOrderDetailButtonRespnsive {
    display: none;
  }

  .myAccOwnerSectionResponsive {
    display: none;
  }

  .myAccountResellTable {
    width: 100%;
  }

  .myAccOwnerHouse {
    width: 100%;
  }

  .responsiveAccOrderDetailsTag {
    display: flex;
    width: 60%;
    padding: 16px 0 0 16px;
  }
  .myAccGetRewardPoints {
    display: none;
  }

  .myAccMoreImage {
    display: none;
  }

  .myAccountMainTableContentParentResponsive {
    width: 100%;
  }

  .drawer-title-parent {
    padding: 24px;
    justify-content: space-between;
    align-items: center;
  }

  .drawer-title-parent .drawer-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
  }

  .drawer-clear-heading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #d9232d;
  }

  .my-account-main-drawer
    .ant-drawer-wrapper-body
    .ant-drawer-header
    .ant-drawer-header-title
    button {
    display: none;
  }
  .my-account-main-drawer .ant-drawer-wrapper-body .ant-drawer-header {
    padding: 0;
  }

  .my-account-main-drawer {
    border-radius: 16px 16px 0 0;
  }

  .drawer-checkbox-heading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
    margin-bottom: 24px;
  }

  .my-account-drawer-checkbox {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .checkbox-option {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
    margin-left: 6px;
  }

  .checkbox-option-parent {
    flex-direction: column;
    gap: 13px;
  }
  .drawer-checkbox {
  }

  .drawer-footer-parent {
    height: 70px;
    box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.12);
  }

  .drawer-footer-parent button {
    width: 48%;
    height: 40px;
    font-weight: 500;
    border-radius: 32px;
    border: solid 1px #9da7b2;
  }

  .drawer-footer-parent {
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }

  .drawer-footer-parent .apply-button {
    background-color: #0354a3;
    color: white;
  }
}
.customerOrderContainer {
  padding: 32px;
  display: flex;
  gap: 32px;
}
.orders-image-main-container {
}
.products-pagination {
  justify-content: center;
  margin: 20px 0;
  display: flex;
  gap: 12px;
}
.products-pagination .ant-pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px !important;
  border: none;
  border-radius: 50px;
}
.products-pagination .ant-pagination-item-active {
  background-color: #0a243f;
  border: none;
}
.products-pagination .ant-pagination-item-active a {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff !important;
}
.products-pagination .ant-pagination-item a {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #0a243f;
}
.products-pagination .ant-pagination-prev,
.products-pagination .ant-pagination-next {
  width: 40px;
  height: 40px;
  margin: 0 0 0 20px;
  border-radius: 50px;
  border: solid 1px #dedede;
  background-color: #fff;
  margin: 0;
}

.products-pagination .anticon {
  font-size: 16px;
}
.quotation-req-sort-by-box {
  display: flex;
  align-items: center;
}
.quotation-req-sort-by-box .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  font-family: Inter;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}
.quotation-req-sort-by-box .ant-select-arrow {
  color: black;
}
.quotation-req-sort-by-box .ant-select-selector .ant-select-selection-item {
  font-weight: 500 !important;
  padding: 0 !important;
  justify-content: flex-start !important;
}
.quotation-req-sort-by-box .ant-select-single {
  height: 40px;
}
.signup-modal-inp {
  height: 40px;
}
.radioGroup .editAddressButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px !important;
  border-color: 1px solid #d9d9d9 !important;
  border-radius: 30px;
  width: auto;
}
.radioGroup {
  display: flex;
  gap: 16px;
}
.editAddressButtonContentParent {
  gap: 16px;
  margin-top: 6px;
}
.active-edit-address-tag {
  color: #fff !important;
  background-color: #0a243f !important;
  border-color: transparent !important;
}
.editAddressButton::before
{
  width: 0 !important;
}