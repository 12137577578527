.customer-personal-info {
  padding: 24px 0;
  border-radius: 16px;
  border: solid 1.5px #dedede;
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
  width: 100%;
}
.customer-personal-info-header {
  font-size: 20px;
  font-weight: 500;
}
.customer-personal-info-I {
  border-bottom: solid 1.5px #dedede;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 24px 24px 24px;
}
.customer-personal-info-prof {
  display: flex;
  gap: 24px;
  align-items: center;
}
.customer-personal-info-upload {
  height: 46px;
  border-radius: 32px;
  background-color: #0354a3 !important;

  font-weight: 500;
}
.customer-personal-info-del {
  height: 46px;
  border-radius: 32px;
  font-weight: 500;
}
.customer-personal-info-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer-personal-info-label {
  line-height: 1.71;
}
.customer-personal-info-value {
  font-size: 16px;
  font-weight: 500;
}
.customer-personal-info-align {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.customer-personal-info-II {
  border-bottom: solid 1.5px #dedede;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
}
.customer-personal-info-textI {
  font-weight: 500;
  color: #6c7c8c;
}
.customer-personal-info-III {
  padding: 24px 24px 0 24px;
}
.verify-align {
  display: flex;
  align-items: center;
  gap: 8px;
}
.customer-personal-info-password{
padding: 24px;
display: flex;
flex-direction: column;
gap: 32px;
border-radius: 16px;
border: solid 1.5px #dedede;
width: 100%;


}
.customer-personal-info-passLab{
    font-size: 20px;
    font-weight: 500;


}
.customer-personal-info-dot{
    height: 8px;
    width: 8px;
    background-color:black;
    border-radius: 8px;
}