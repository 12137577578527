.service-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.service-container-text {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
  text-decoration: underline;
}
.service-number-text {
  background-color: #f8e6b6;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-list-main-Wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.service-list-main-sub-Wrapper {
  padding: 16px 24px;
  background-color: #f8f8f8;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service-list-main-sub-Wrapper-view-button {
  color: #0a243f;
  height: 38px;
  padding: 0px 20px;
  border: 1px solid #0a243f;
}
.service-list-main-sub-Wrapper-view-button:hover {
  opacity: 0.6;
  border: 1px solid #0a243f !important;
}
.service-list-details {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-list-details-sub {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 30%;
}
.service-list-details-sub-service-id {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
}
.service-list-details-sub-service-date {
  font-size: 14px;
  font-weight: normal;
  color: #0a243f;
  opacity: 0.6;
}
.service-list-details-sub-type-container {
  display: flex;
  width: 60%;
  justify-content: space-between;
}
.service-list-details-sub-type-tag-container {
  width: 20%;
}
