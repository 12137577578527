.amountSelectedCard-container{
padding: 24px;
border-radius: 8px;
box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
border: solid 1px #eaeaea;
display: flex;
flex-direction: column;
gap: 1rem;


}