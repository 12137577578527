.cancelled-rfq-table .ant-table {
  opacity: 0.6;
  border-radius: 0;
  border-radius: 0 0 10px 10px;
}
.cancelled-rfq-table .ant-spin-container {
  border-radius: 0 0 10px 10px;
}
.cancelled-main-container {
  position: relative;
}
.cancelled-main-banner {
  position: absolute;
  top: -33px;
  background: #f0483e;
  width: 100%;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px 8px 0 0 ;
}
.cancelled-main-banner > span {
  color: #fff;
}
