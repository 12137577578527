.input-actions-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.skeleton-input {
  background-color: #fff;
  width: 480px;
  padding: 5px;
}
.create-order-search-input {
  height: 48px;
  width: 480px;
  background: #fff;
}
.create-order-cancel-button {
  padding: 13px 40px;
  height: 48px;
  font-weight: 500;
  border: solid 1px #9da7b2;
  border-radius: 8px;
}
.create-order-confirm-button {
  padding: 13px 60px 13px 60px;
  font-weight: 500;
  background-color: #0354a3;
  color: #fff;
  height: 48px;
  border-radius: 8px;
}
.create-order-confirm-button:hover {
  color: #fff !important;
}
