.amount-transfer-label {
  display: flex;
  justify-content: space-between;
  width: 500px;
}
.available-money-notify {
  border-radius: 16px;
  background-color: #f1f1f2;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
