.customer-wallet-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.customer-wallet-heading {
  font-size: 20px;
  font-weight: 500;
}
.customer-wallet-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.customer-wallet-points {
  border-radius: 16px;
  border: solid 1px #dedede;
  padding: 24px;
  width: 100%;
  display: flex;
  gap: 120px;
}
.wallet-icon-wrapper {
  height: 54px;
  width: 54px;
  border-radius: 16px;
  background-color: #f1f1f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wallet-points-align {
  display: flex;
  gap: 16px;
  align-items: center;
}
.wallet-text-wrapper {
  display: flex;
  flex-direction: column;
}
.cash-text {
  font-size: 24px;
  font-weight: bold;
}
.earned-text {
  font-size: 16px;
  font-weight: 500;
}
.customer-wallet-notify {
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  background-color: #f8e6b6;
  padding: 16px;
}
.customer-wallet-notifyI {
  font-size: 14px;
  border-radius: 16px;
  background-color: #f1f1f2;
  padding: 16px;
}
