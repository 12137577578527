.description-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 90%;
}
.description-container-heading {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
}
.amount-container-heading {
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.mob-credit-balance-container {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 8px;
  border: solid 1px #dedede;
  gap: 120px;
}
.mob-credit-balance-sub-container {
  display: flex;
  gap: 20px;
  align-items: center;
}
.mob-credit-balance-sub-image-container {
  padding: 10px;
  background-color: #f1f1f2;
  border-radius: 16px;
}
.mob-credit-balance-sub-text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mob-credit-balance-sub-text-container-balance {
  font-size: 20px;
  font-weight: bold;
  color: #0a243f;
}
.mob-credit-balance-sub-text-container-text {
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
}
.mob-credit-table {
  border: 1px solid #dedede;
  border-radius: 10px;
}
.mob-credit-table > .ant-spin-nested-loading,
.mob-credit-table .ant-table,
.mob-credit-table .ant-table-container,
.mob-credit-table .ant-table-content,
.mob-credit-table .ant-spin-container {
  border-radius: 10px;
}
