.heading-tooltip {
  /* display: inline; */
}
.ant-tooltip-inner {
  color: #0a243f !important;
}
.tooltip-container {
  display: flex;
  gap: 3px;
  align-items: center;
}
.heading-text {
  font-size: 14px;
}
.status-container {
  width: 500px;
  padding: 5px;
}
.tooltip-heading {
  margin-bottom: 10px;
}
.toggle-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
