.order-list-table .ant-table-row-expand-icon-cell {
  width: 0 !important;
}

.order-list-table table tbody tr td:first-child {
  border-right: none;
}

.order-list-table .ant-table-expanded-row .ant-table-cell {
  background-color: #ffffff;
  padding: 16px 30px 16px 48px;
}

.cursor {
  cursor: pointer;
}
.order-id-text {
  color: #2973f0;
  cursor: pointer;
}
.plus-icon {
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  margin-left: 1rem;
}
.header-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
}
.action-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.action-sub-container {
  display: flex;
  gap: 16px;
}
.create-order-button {
  background-color: #0354a3;
  color: #fff;
  font-weight: 500;
  height: 46px !important;
}
.create-order-button:hover {
  color: #fff !important;
}
.title_sorter {
  display: flex;
  justify-content: space-between;
}

.sorter_container_icon {
  display: flex;
  flex-direction: column;
}
.sorter_icon {
  height: 10px;
  cursor: pointer;
}
