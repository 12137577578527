/* :where(.css-dev-only-do-not-override-txh9fw).ant-steps */
.steps-wrapper
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #01a685;
}
/* :where(.css-dev-only-do-not-override-txh9fw).ant-steps */
.steps-wrapper
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #868a8a;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background: #e4e4e4;
}
.activity-collapse {
  width: 100%;
}
.steps-sub-wrapper
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  width: 100%;
  padding-inline-end: 0px;
}
.steps-sub-wrapper > .ant-steps-item-container > .ant-steps-item-tail {
  top: 18px !important;
}
.steps-sub-wrapper > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #01a685 !important;
}
.steps-sub-wrapper > .ant-steps-item-container > .ant-steps-item-icon {
  margin-top: 18px !important;
}
.activity-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
  padding: 0 0 12px 6px;
}
.activity-collapse
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 0 20px 6px;
}
.activity-drawer-container {
  border-radius: 16px 0 0 16px;
}
.panel-header-container {
  height: 44px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.panel-date {
  font-size: 14px;
  font-weight: normal;
  color: #6c7c8c;
}
.panel-name {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
}
.panel-edited {
  font-size: 14px;
  font-weight: 500;
  color: #01a685;
}
.panel-name-container {
  display: flex;
  gap: 4px;
}
.panel-content-container {
  padding: 16px;
  border-radius: 12px;
  background-color: #f3f2ef;
}
.panel-content-text {
  font-weight: normal;
  font-size: 14px;
  color: #0a243f;
}
