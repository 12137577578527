.card-skeleton-container {
  width: 50%;
  height: 440px;
}
.status-card-main-container {
  width: 22.2%;
  height: 120px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.status-card-button-skeleton {
  height: 20px !important;
}
.status-card-sub-container {
  width: 70%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (max-width: 1450px) {
  .card-skeleton-container {
    width: 100%;
  }
}
