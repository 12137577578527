.vip-member-icon-container {
  /* background-color: #5b6c7e; */
  background-image: linear-gradient(108deg, #0a243f -34%, #5b6c7e 110%);
  /* position: absolute; */
  /* bottom: -3px; */
  /* left: -24px; */
  border-radius: 12px 0px 12px 0px;
  height: 28px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vip-member-icon-container-inactive {
  /* background-color: #5b6c7e; */
  /* background-image: linear-gradient(108deg, #0a243f -34%, #5b6c7e 110%); */
  /* position: absolute; */
  /* bottom: -3px; */
  /* left: -24px; */
  /* border-radius: 12px 0px 12px 0px;
  height: 28px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center; */
}
.mob-credit-container-active {
  width: 100%;
  height: 100px;
  background-color: #55a77b;
  padding: 8px 16px 10px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mob-credit-container-inactive {
  width: 100%;
  height: 100px;
  background-color: #dedede;
  padding: 8px 16px 10px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mob-credit-icon-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mob-credit-available-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
.approved-text-active {
  font-size: 12px;
  font-weight: normal;
  color: #fff;
}
.approved-text-inactive {
  font-size: 12px;
  font-weight: normal;
  color: #0a243f;
  opacity: 0.6;
}
.approved-text-incomplete {
  font-size: 12px;
  font-weight: normal;
  color: #fff !important;

}
.available-text-active {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.available-text-inactive {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
  color: #0a243f;
}
.available-heading-text-active {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.available-heading-text-inactive {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
  color: #0a243f;
}
.vip-icon-banner-container-active {
  height: 50px;
  background-image: linear-gradient(94deg, #0a243f -31%, #5b6c7e 110%);
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
}
.vip-icon-banner-container-inactive {
  height: 50px;
  border-radius: 12px 12px 0px 0px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
}
.vip-icon-banner-container-child {
  display: flex;
  gap: 10px;
  align-items: center;
}
.vip-icon-banner-container-child-active {
  display: flex;
  gap: 10px;
  align-items: center;
}
.Active-text {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.Inactive-text {
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.custom-header-name {
  display: flex;
  gap: 10px;
  align-items: center;
}
.text-danger
{
  color: #f0483e !important;
  font-size: 12px;
  font-weight: 600;
}
.mob-credit-container-inactive
{
  background-color: #ffebe6 !important;
}
.mob-credit-container-incomplete
{
  background-color: #1c1c1e !important;
  width: 100%;
  height: 100px;
  padding: 8px 16px 10px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.available-heading-text-incomplete {
  font-size: 14px;
  font-weight: 500;
  /* opacity: 0.6; */
  color: #fff;
}
.available-text-incomplete {
  font-size: 14px;
  font-weight: 500;  
  color: #fff;
}
.text-progress
{
  font-size: 14px;
  font-weight: 500;  
  color: #fff;
}