.amountCreditModal-containerI {
  padding: 8px 24px;
  border-bottom: 1px solid #dedede;
}
.amountCreditModal-containerII {
  padding: 24px;
  height: 250px;
  display: flex;
  flex-direction: column;
  gap:16px
}
.amountCreditModal-textI {
    font-size: 20px;
     font-weight: 500 
}
.amountCreditModal-textII {
    font-size: 16px;
    font-weight: 500;
}
.amountCreditModal-checkbox{
font-weight: 500;
padding-bottom: 8px;
opacity: 0.9;


}