.add-vehicle{
    margin-top: 12px;
    width: 100%;
    border-radius: 16px;
    border: solid 1px #dedede;
    display: flex;
    justify-content: center;
    align-items: center;
height: 292px;
}
.noVehicle-align{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}