.headingItem {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.heading-text {
  font-size: 20px;
  font-weight: 500;
  color: #1a1a1a;
}
.row-container {
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.row-container:first-child {
  border-radius: 8px 8px 0px 0px;
}
.row-container:last-child {
  border-bottom: 1px solid #dedede;
  border-radius: 0px 0px 8px 8px;
  min-height: 45px;
}
.label-container {
  background-color: #e8e8e8;
  padding: 10px;
  width: 35%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.edit-container {
  width: 65%;
}
.form-item {
  margin: 0px;
}
.date-picker-container-edit {
  display: flex;
  align-items: center;
  width: 65%;
  padding: 0 10px;
}
.required-star {
  color: #bf2600;
  display: inline;
}
.multilevel-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.disable-date-picker {
  background-color: #f5f5f5;
  width: 65%;
  padding: 0 10px;
}
