.my-account-order-detail-modal .ant-modal-content {
  padding: 0 !important;
}
.my-account-order-detail-modal .ant-modal-content .ant-modal-body {
  max-height: 600px;
  overflow-y: auto;
}
.ant-order-detail-modal .ant-modal-content .ant-modal-header {
  margin: 0 !important;
}
.my-account-order-detail-title-parent {
  align-items: center;
  height: 62px;
  padding-left: 24px;
}
.my-account-order-detail-title-parent .text {
  height: 24px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}
.content-item-parent {
  height: auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 24px;
  display: flex;
}
.content-item-parent-image-button-parent {
  width: 65%;
  flex-wrap: nowrap;
  gap: 16px;
}
.myAccountModalContentParent {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.content-item-title-parent {
  /* width: 40%; */
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.content-item-parent-image-button-parent-modal {
  width: 100%;
  display: flex;

  gap: 16px;
}
.content-item-parent-image-button-parent-modal img {
  object-fit: contain;
}
.content-item-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #0a243f;
}
.rating-component-content-parent .content-item-qunatity {
  margin-left: 24px;
}
.content-item-qunatity {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #6c7c8c;
}
.content-item-qunatity-parent {
  gap: 12px;
}
.content-item-button-parent {
  width: 25%;
}
.content-item-button-parent button {
  width: 100%;
  height: 40px;
  border-radius: 32px;
  border: solid 1.5px #dedede;
  background-color: #fff;
}
.content-item-bullet {
  width: 12px;
  height: 12px;
  background-color: #4fb589;
  border-radius: 10px;
}
.content-item-return-desc-parent {
  gap: 18px;
  padding-left: 24px;
  flex-direction: column;
}
.content-item-return-desc {
  align-items: center;
  flex-wrap: nowrap;
  gap: 12px;
}
.rating-component-star {
  gap: 24px;
  margin-bottom: 24px;
  align-items: flex-start;
  flex-direction: column;
}
.rating-input-parent {
  flex-direction: column;
  width: 100%;
  padding-right: 24px;
  gap: 16px;
  align-items: flex-end;
}
.rating-input-parent div input {
  height: 52px;
  width: 100%;
}
.rating-input-parent div {
  width: 100%;
}
.rating-input-parent button {
  width: 30%;
  height: 40px;
  color: white;
  border-radius: 32px;
  background-color: #0354a3;
}
.content-item-amount {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: right;
  color: #0a243f;
}
.my-account-order-detail-modal .editAddressFooterParent .header-content {
  flex-direction: column;
}
.my-account-order-detail-modal .editAddressFooterParent {
  justify-content: space-between;
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.1);
}
.my-account-order-detail-modal .editAddressFooterParent .editFooterSaveButton {
  width: 60%;
}

.cartSectionDivider {
  height: 12px;
  background-color: #f1f1f2;
}
.content-item-main-parent {
  display: flex;
  flex-direction: column;
  /* min-height: 363px  */
}
