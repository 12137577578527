.slick-slider .slick-track {
  display: flex !important;
  gap: 20px !important;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-arrow.next {
  right: -15px;
  z-index: 5;
}

.custom-arrow.prev {
  z-index: 5;
  left: -25px;
}
.cardComponent {
  width: 100% !important;
  height: 90%;
}
.ant-avatar > img {
  object-fit: contain;
}
.productImage {
  margin: 0 auto;
  display: flex;
  border-radius: 8px;
  object-fit: contain;
}
.cardAddButton {
  border-radius: 24px;
  color: white;
  background: #0354a3;
  float: right;
  margin-top: -10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
  border: none;
}
.cardAddButton:hover {
  border: none;
  color: white !important;
}
.cardNotifyButton {
  border-radius: 24px;
  color: black !important;
  color: white;
  background: #fff;
  float: right;
  margin-top: -10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
  border: none;
}
.cardNotifyButton :hover {
  border: none;
}
.productDescription {
  margin-top: 35px;
  font-family: Inter;
}
.productPriceRow {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.productOfferPrice {
  font-weight: 700;
  font-size: 18px;
  color: #0a243f !important;
}
.productBeforePrice {
  text-decoration: line-through;
  color: lightgrey;
}
.productsRatingCol {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.productRating {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
}
.productDeals {
  margin: 10px 0px 5px;
}
.downOutlinedIcon {
  width: 12px;
  height: 6px;
}
.deliveryTodayStatusButton {
  width: 100%;
  height: 30px;
  background-color: #f1f1f2;
  border-radius: 8px;
  border: none;
  font-size: 12px;
}
.deliveryTodayStatusButton :hover {
  color: black;
}
.deliveryStatusButton {
  width: 100%;
  height: 30px;
  background-color: #eaf7eb;
  border-radius: 8px;
  border: none;
  font-size: 12px;
}
.deliveryStatusButton :hover {
  color: black;
}
.slick-disabled {
  display: none;
}
.imageMArgin {
  margin-top: 25px;
}
.productTag {
  margin-bottom: 5px;
  border-radius: 8px;
  border: solid 1px #9da7b2;
  background-color: #fff;
  font-weight: 500;
}
.productTagSold {
  border: none !important;
  background-color: #f1f1f2;
}
.crouselContainer {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}
.itemDiscount {
  font-weight: 700;
  color: #34b439;
}
@media only screen and (max-width: 650px) {
  .crouselContainer {
    width: 95%;
  }
  .crouselContainer .slick-slide {
    width: 150px;
  }
  .productImage {
    width: 104px;
    height: 104px;
  }
  .productDescription {
    font-size: 12px;
  }
  .productOfferPrice {
    font-size: 14px;
    display: block;
    margin: 14px 0 8px;
  }
  .productPriceRow {
    display: initial;
  }
  .productBeforePrice {
    font-size: 12px;
    margin-right: 10px;
  }
  .itemDiscount {
    font-size: 12px;
  }
  .iconRating {
    width: 12px;
    height: 12px;
  }
  .productRating {
    font-size: 10px;
  }
}
