.service-request-drawer > .ant-drawer-wrapper-body > .ant-drawer-footer {
  padding: 0px;
}
.service-request-drawer-head-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-request-drawer-cross-container {
  width: 48px;
  height: 48px;
  padding: 16px;
  border-radius: 24px;
  cursor: pointer;
}
.service-request-drawer-service-number-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.service-request-drawer-service-date-container {
  margin-top: 10px;
}
.service-request-drawer-service-date {
  font-size: 14px;
  color: #0a243f;
  opacity: 0.6;
}
.service-request-drawer-service-body-main-container {
  padding: 10px 24px;
}
.service-request-drawer-service-body-main-payment-link {
  padding: 10px;
  border-radius: 8px;
  background-color: #f2f2f2;
  margin-top: 10px;
}
.service-request-drawer-service-body-main-payment-link-text {
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.service-request-drawer-service-body-main-comment-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.service-request-drawer-service-body-main-comment-heading {
  font-size: 14px;
  font-weight: normal;
  color: #0a243f;
}
.service-request-drawer-service-body-main-comment-text {
  font-size: 14px;
  font-weight: normal;
  color: #6c7c8c;
  margin-top: 5px;
}
.drawer-divider {
  background-color: #f1f1f2;
  height: 10px;
  width: 100%;
}
.service-request-drawer-service-body-main-image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 18px;
}
.service-request-drawer-service-body-main-container-customer-details {
  padding: 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
}
.service-comments-wrrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
