/* .customer-quotation-request {
  padding: 24px 0;
  border-radius: 16px;
  border: solid 1.5px #dedede;
  width: 100%;
}
.customer-quotation-cardI {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px 24px;
  border-bottom: solid 1.5px #dedede;
  align-items: center;
}
.customer-quotation-content {
  display: flex;
  gap: 1rem;
}
.customer-quotation-text {
  display: flex;
  flex-direction: column;
}
.customer-quotation-head {
  color: #6c7c8c;
  line-height: 1.71;
}
.customer-quotation-val {
  font-weight: 500;
  line-height: 1.71;
}
.customer-quotation-total {
  font-size: 18px;
  color: #0a243f;
  opacity: 0.6;
  padding-right: 0.5rem;
}
.customer-quotation-amount {
  font-size: 18px;  
  font-weight: 500;
}
.customer-quotation-cardII{
padding: 24px 24px 0 24px;
display: flex;
flex-direction: column;
gap: 24px;



}
.customer-quotation-status{
display: flex;
justify-content: space-between;
align-items: center;



}
.customer-quotation-stbar{
padding: 8px 16px;
font-weight: 500;
border-radius: 8px;

}
.customer-quotation-button{
    border: solid 1.5px #dedede;
    border-radius: 26px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 230px;
}
.customer-quotation-button-wrapper{
    display: flex;
  justify-content: flex-end;
} */
.ant-select-arrow
{
  height: 0px !important;
}