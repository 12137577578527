.modal-table-wrapper {
  padding: 16px;
}
.styled-table {
  border: 1px solid #dedede;
  border-radius: 16px;
}
.styled-summary-modal {
  width: 50% !important;
}
.custom-font {
  font-size: 14px;
  font-weight: 500;
}
.noVehicle-text {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 1rem;
}
.error {
  border-color: #ff0000; 
}

.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
}
