.footer-main-container {
  display: flex;
  justify-content: center;
  padding: 28px;
  width: 100%;
  gap: 10px;
}
.footer-cancel-button {
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-save-button {
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  color: white;
  background-color: #0354a3;
  display: flex;
  justify-content: center;
  align-items: center;
}
