.sub-order-back-button {
  height: 46px;
  font-weight: 500;
}
.sub-order-main-container {
  width: 100%;
  display: flex;
}
.sub-order-main-container-left {
  width: 60%;
}
.sub-order-main-container-right {
  padding-left: 20px;
  width: 40%;
}
.customer-sub-order-container {
  padding: 32px;
  display: flex;
  gap: 32px;
}
.sub-order-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.contact-us-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  border-radius: 16px;
}
.sub-order-note {
  margin-top: 20px;
  padding: 16px;
  border-radius: 8px;
  background-color: #f1f1f2;
  font-weight: 500;
}
.sub-order-note-text-1 {
  color: #0a243f;
}
.sub-order-note-text-2 {
  color: #0a243f;
  opacity: 0.5;
}
.sub-order-right-inside-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.sub-orders-card-container {
  margin-top: 20px;
  gap: 32px;
}
.sub-orders-card-container-parent {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  height: auto;
  width: 100%;
  flex-direction: row;
}
.sub-orders-card-container-parent-header {
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}
.sub-order-view-all-item-button {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-order-summary-container {
  border-radius: 8px;
  border: 1px solid #dedede;
}
.sub-order-summary-text-heading {
  padding: 24px;
  font-size: 20px;
  font-weight: 500;
}
.sub-order-total-amount {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 500;
  padding: 18px 24px;
  border-bottom: 1px solid #dedede;
}
.sub-order-pending-amount {
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sub-order-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
