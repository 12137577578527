.walletBank-container{
padding: 32px;
display: flex;
flex-direction: column;
gap: 24px;


}
.wallet-bank-styledText{
    font-size: 16px;
}