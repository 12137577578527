.categories-heading_wrapper{
    display:flex;
    justify-content:space-between;
    align-items:center
}
.cat-btn{
    height:46px;  font-size: 16px;
        font-weight: 500;
        background-color:#0354a3 !important


}