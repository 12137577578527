.customerCard-container {
  width: 31.9%;
  padding: 20px 24px;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
