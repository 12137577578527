.wallet-amount-input:hover {
  border-color: black !important;
}
.wallet-amount-input > input {
  text-align: end;
  width: 86px;
}
.wallet-amount-input:target {
  border-color: black !important;
  box-shadow: none !important;
}
.wallet-amount-input:focus {
  border-color: black !important;

  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-1vr7spz).ant-picker-focused {
  border-color: black !important;
  box-shadow: none !important;
}
.amount_container {
  border-radius: 8px;
  border: 1px solid #384853;
  display: flex;
  justify-content: space-between;
  padding: 4px 4px 0px 4px;
  height: 52px;
  margin-bottom: 20px;
}
.amount_label_text {
  color: black;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 4px;
}
.amount_container .ant-form-item {
  margin-bottom: 0px;
}
.amount_container .ant-select-selector {
  border: none !important;
}
.amount_container_debit .ant-select-selector {
  background-color: #f3d6d6 !important;
}
.amount_container_credit .ant-select-selector {
  background-color: #ceecd8 !important;
}
