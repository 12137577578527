.custom-input:hover,
.custom-input:focus {
  border: 2px solid #0a243f;
}
.custom-input-pass:focus.ant-input-affix-wrapper-focused,
.custom-input-pass:hover.ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover,
.custom-input-pass:focus.ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border: 2px solid #0a243f;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-input-affix-wrapper-focus,
:where(.css-dev-only-do-not-override-17a39f8).ant-input-affix-wrapper-focused {
  border:2px solid #0a243f !important;
  box-shadow: none !important;
}
