.sub-order-images-container {
  gap: 12px;
  height: 50px;
  margin-top: 15px;
}
.sub-order-images-avatar {
  width: 50px;
  height: 50px;
  position: relative;
}
.sub-order-images-quantity-wrapper {
  width: 30px;
  height: 24px;
  border-radius: 16px;
  border: 1.5px solid #dedede;
  background-color: #fff;
  position: absolute;
  left: 10px;
  bottom: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.more-image-avatar {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #f1f1f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.more-text {
  color: #0a243f;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
