.ready_for_pickup {
  background-color: #f8e6b6;
  color: #384853;
}
.waiting {
  background-color: #e8e8e8;
  color: #0a243f;
}
.delivered {
  background-color: #abf5d1;
  color: #006644;
}
.cancelled {
  background-color: #ffebe6;
  color: #bf2600;
}
.rejected_by_seller {
  background-color: #ffebe6;
  color: #bf2600;
}
.credit_note_issued {
  background-color: #ecebff;
  color: #384853;
}
.return_request {
  background-color: #ecebff;
  color: #384853;
}
.out_for_delivery {
  background-color: #b3edfd;
  color: #384853;
}

.drop-down-container {
  cursor: pointer;
  position: relative;
  display: flex;
}
.label-text {
  font-weight: 500;
  padding: 8px;
  border-radius: 8px;
}
.label-text-pro {
  font-weight: 500;
  padding: 8px;
  border-radius: 8px 0 0px 8px;
}
.arrow-container {
  width: 45px;
  border: 1px solid #0a243f;
  padding: 9px;
  background-color: #ffff;
  border-radius: 0 8px 8px 0;
}
.menu-container {
  min-width: 240px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  align-items: center;
}
.menu-text {
  font-weight: 500;
  padding: 8px;
  border-radius: 8px;
}
.status-menu li:not(:last-child)
{
  border-bottom: 1px solid #dedede;

}
.status-menu li
{

  border-radius: unset !important;
}