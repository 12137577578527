.pickup-header {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 10px 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}
.pickup-header-text {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.pickup-header-button {
  background-color: #0354a3;
  height: 48px;
  color: #fff;
}
.pickup-header-button:hover {
  color: #fff !important;
}
.pickup-header-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pickup-card {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  border: 1px solid #dedede;
  border-radius: 8px;
}
.pickup-card-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
}
.map-url-heading {
  display: block;
  line-height: 1.5;
  font-size: 16px;
  color: #0a243f;
  margin: 10px 0;
}
.map-url-text {
  display: block;
  line-height: 1.5;
  font-size: 16px;
  color: #0354a3;
  margin-bottom: 10px;
}
.pickup-card-actions {
  background-color: #dedede;
  padding: 10px 20px;
}
.edit-go-container {
  height: 50px;
  display: flex;
  align-items: center;
  color: #0354a3;
}
.pickup-divider {
  background-color: #0a243f;
  height: 2rem;
}
.actions-buttons {
  background-color: #dedede;
  border: none;
  color: #0354a3;
}
