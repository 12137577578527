.customerNote-wrapper {
  padding: 24px;
  border-radius: 16px;
  border: solid 1px #dedede;
  display: flex;
  flex-direction: column;
  gap: 40px;
  
}
.translation-text {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.translation-textI {
    font-style: italic;
  font-size: 16px;
}
.customerNoteText-wrapper{
    display: flex;
    justify-content: flex-end;
}
