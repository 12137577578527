.add-vehicle-type {
  padding: 8px 16px 16px 16px;
  border-bottom: 12px solid #f1f1f2;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}

.vehicle-type-del {
  border-radius: 8px;
  border: solid 1px #bac7d5;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-vehicle-type-wrapper {
  display: flex;
  justify-content: space-between;
}
.vehicle-disc {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.vehicle-disc-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.vehicle-disc-key {
  width: 50%;
  color: #6c7c8c;
}
.vehicle-disc-align {
  display: flex;
  width: 100%;
}
.logistics-partner {
  padding: 16px;
  border-bottom: 12px solid #f1f1f2;

  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Logistics-dropdown-wrapper {
  display: flex;
  flex-direction: column;
}
.logistic-input {
  height: 46px;
  border-color: #bac7d5;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}
.bill-no-wrapper {
  display: flex;
  gap: 24px;
}
.billing-align {
  width: 50%;
}
.assigned-date-wrapper{
padding: 16px;
  border-bottom: 12px solid #f1f1f2;
  display: flex;
  flex-direction: column;
gap: 1rem;
}
.addVehicle-text-bold{
  font-size: 16px;
  font-weight: 500;


}
.addVehicle-dateTime-align{
display: flex;
width: 100%;
gap: 24px;
}
.addVehicle-date{
border: 1px solid #bac7d5;
border-radius: 8px;
height: 46px;
padding: 8px;
width: 50%;


}
.addVehicle-date{
  width: 50%;
}
.addVehicle-dragger{
  position: relative;
  border-bottom: 12px solid #f1f1f2;
padding: 16px;
}
.logistic-input.error {
  border: 1px solid red;
  background-color: rgb(243, 227, 227);
}
.addVehicle-date.error {
  border: 1px solid red;
}
.addVehicle-time.error {
  border: 1px solid red;
  border-radius: 8px;
  background-color: rgb(243, 227, 227);

}
.error-handle{
position: absolute;

}

.logistics-partner-wrapper
{
  padding: 16px;
  border-radius: 16px;
  background-color: #f8faf7;
}
.transport-main-wrapper
{
  padding: 24px;
}
.vehicle-border
{
  padding: 16px 16px 16px 16px;
  border-radius: 16px;
  border: solid 1px #dedede;
}