.subOrderStyle {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 16px;
  margin-bottom: 16px;
}

.subIdName {
  display: flex;
  align-items: center;
}

.subId {
  margin-right: 50px;
}
.subStatusTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}
.delayFlag {

  align-items: center;
  width: 100%;
}
.subIdName 
{
  width: 50%;
}
.colorSuccess {
  color: #006644;
  background-color: #abf5d1;
}

.colorDisabled {
  color: #0a243f;
  background-color: #f5f5f5;
}

.subDataStatus {
  font-weight: 500;
  height: 32px;
  font-size: 12px;
  padding: 6px;
}

.subDataId {
  color: #0a243f;
  font-size: 14px;
  font-weight: 500;
}
