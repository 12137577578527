.view-detail-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.view-detail-status {
  padding-bottom: 30px;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.view-detail-btn {
  font-size: 14px;
  font-weight: 500;
  height: 46px;
  padding: 0 24px;
}

.detail-activity {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-invoice-wrapper {
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.detail-heading {
  font-size: 20px;
  font-weight: 500;
}

.seller-view {
  font-weight: 500;
  color: #2973f0;
}

.comp-wrapper {
  display: flex;
  gap: 24px;
}

.detail-status {
  display: flex;
  gap: 24px;
  align-items: center;
}

.details-btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.detail-btn {
  height: 46px;
  padding: 0 24px;
  font-weight: 500;
}

.vehicle-notify {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.vehicle-text {
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  background-color: #f8e6b6;
  padding: 24px;
  width: 100%;
}

.user-profile {
  border: 1px solid #dedede;
  border-radius: 8px;
}

.assigned-heading {
  color: #0a243f;
  font-size: 14px;
  font-weight: normal;
}

.assigned-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.timer-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}

.timer {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.timer-wrapper {
  padding: 12px 11px 12px 12px;
  border-radius: 8px 0px 0px 8px;
  background-color: #807bff;
}

.timer-overall-container.section-disabled .timer-wrapper {
  background-color: #9a9a9a !important;

}

.timer-overall-container {
  align-items: center;
  border-radius: 8px;
  background-color: #f2f1ff;
  /* height: 88px; */


}

.timer-overall-container.section-disabled {
  background-color: #f8f8f8 !important;

}

.align-items-center {
  align-items: center;
}

.comp-wrapper {
  padding: 12px 11px 12px 12px;
}

.text-einvoice {
  font-size: 14px;
  font-weight: 600;
}

.timer-font-style {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.timer-font-style-edit {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.timer-font-style-sm {
  font-size: 12px;
  color: #fff;
  font-weight: normal;
}

.timer-unit-label {
  display: block;
  margin-right: 5px;
}
.list-view-error
{
  list-style: none;
  padding: 6px !important;
}
.error-panel .ant-collapse-header
{
  background-color: #ffebe6;
}
.error-panel .ant-collapse-content
{
  background-color: #fff7f5;
}
.error-panel  .ant-collapse-header-text span
{
display: flex;
align-items: center;
gap: 8px;
color: #0a243f;
font-size: 14px;

}
.amount-column {
  padding-right: 24px !important;
}