.top-action-container {
  display: flex;
  gap: 1rem;
}
.cancelBtn {
  height: 48px;
  font-weight: 500;
  padding: 0 40px;
}
.saveBtn {
  background-color: #0354a3;
  height: 48px;
  font-weight: 500;
  padding: 0 40px;
}
.bottom-action-container {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;
}
