.ant-switch-checked {
  background: #01a685 !important;
}
.ant-select-selector {
  border-radius: 115px !important;
}

.mob-list-table {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.mob-list-table table tbody tr td:first-child {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  padding: 0;
  text-align: center;
}

.table-icon {
  font-size: 21px;
  color: #6c7c8c;
  text-align: center;
  padding-bottom: 14px;
}
